/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/QuixThe2nd/Starfiles-JSDelivr@master/js/functions.min.js
 * - /gh/QuixThe2nd/Starfiles-JSDelivr@master/js/head.min.js
 * - /gh/QuixThe2nd/Starfiles-JSDelivr@master/js/index.min.js
 * - /npm/jszip@3.10.1/dist/jszip.min.js
 * - /npm/crypto-js@4.2.0/crypto-js.min.js
 * - /gh/QuixThe2nd/Starfiles-JSDelivr@master/js/upload.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
